import { isPlatformBrowser } from '@angular/common';
import type { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Datastore } from '@freelancer/datastore';
import type {
  SeoNavbar,
  SeoNavbarCollection,
  SeoNavbarLink,
  SeoNavbarParent,
} from '@freelancer/datastore/collections';
import { ContainerSize } from '@freelancer/ui/container';
import { UserAgent } from '@freelancer/user-agent';
import type { Observable, Subscription } from 'rxjs';
import { merge, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-seo-navbar',
  template: `
    <div class="SeoNavbarWrapper">
      <fl-container
        [size]="size"
        [sizeDesktopXLarge]="containerSizeDesktopXLarge"
        [mobilePadding]="true"
      >
        <div
          *ngIf="showLeftShadow"
          class="SeoNavbarWrapper-shadow SeoNavbarWrapper-shadow-left"
        ></div>
        <div
          *ngIf="showRightShadow"
          class="SeoNavbarWrapper-shadow SeoNavbarWrapper-shadow-right"
        ></div>
        <div
          class="SeoNavbar-content"
          #seoNavbar
        >
          <ng-container *ngIf="seoNavBar$ | flAsync as seoNavbar">
            <ng-container *ngIf="seoNavbar[0] as parentNodes">
              <div
                class="SeoNavbar-item"
                *ngFor="
                  let node of parentNodes?.nodes;
                  let i = index;
                  trackBy: trackByName
                "
                [class.SeoNavbar-item-hover]="i === activeIndex"
                #seoNavbarItem
              >
                <app-mega-menu
                  [crawlable]="isCrawler"
                  [treeData]="node"
                  (isActiveTrigger)="handleisActiveTrigger($event, i)"
                ></app-mega-menu>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </fl-container>
    </div>
  `,
  styleUrls: ['./seo-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeoNavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  nodes: readonly SeoNavbarParent[];
  activeIndex: number;
  seoNavBar$: Observable<readonly SeoNavbar[]>;

  @ViewChild('seoNavbar', { read: ElementRef })
  metaMenuContainerRef: ElementRef;

  @ViewChildren('seoNavbarItem', { read: ElementRef })
  megaMenuRef: QueryList<ElementRef>;

  firstObservedElem: ElementRef;
  lastObserveElem: ElementRef;

  firstObjectObserver: IntersectionObserver;
  lastObjectObserver: IntersectionObserver;

  showLeftShadow = false;
  showRightShadow = false;
  private carouselItemsSubscription?: Subscription;

  size: ContainerSize;

  @Input() set containerSize(value: ContainerSize) {
    this.size = value || ContainerSize.DESKTOP_LARGE;
  }

  @Input() containerSizeDesktopXLarge?: ContainerSize;

  @Input() isCrawler?: boolean;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private datastore: Datastore,
    private changeDetector: ChangeDetectorRef,
    private userAgent: UserAgent,
  ) {}

  ngOnInit(): void {
    this.nodes = [];

    this.isCrawler = this.isCrawler ?? this.userAgent.isBot();

    const seoNavBarCollection$ =
      this.datastore.collection<SeoNavbarCollection>('seoNavbar');
    this.seoNavBar$ = seoNavBarCollection$.valueChanges();
  }

  ngOnDestroy(): void {
    if (this.carouselItemsSubscription) {
      this.carouselItemsSubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.carouselItemsSubscription = merge(
        of(this.megaMenuRef.toArray()),
        this.megaMenuRef.changes.pipe(map(itemList => itemList.toArray())),
      ).subscribe(el => {
        this.initalizeSeoNavbar();
      });
    }
  }

  initalizeSeoNavbar(): void {
    const megaMenuItem = this.megaMenuRef.toArray();
    if (megaMenuItem.length > 0) {
      if (this.firstObjectObserver && this.firstObservedElem) {
        this.firstObjectObserver.unobserve(
          this.firstObservedElem.nativeElement,
        );
      }

      if (this.lastObjectObserver && this.lastObserveElem) {
        this.lastObjectObserver.unobserve(this.lastObserveElem.nativeElement);
      }

      const observableOptions = {
        root: this.metaMenuContainerRef.nativeElement,
        threshold: [0.1, 0.9],
      };

      this.firstObjectObserver = new IntersectionObserver((entry, obs) => {
        const oldLeftShadow = this.showLeftShadow;
        this.showLeftShadow = entry[0].intersectionRatio < 0.9;
        if (oldLeftShadow !== this.showLeftShadow) {
          this.changeDetector.markForCheck();
        }
      }, observableOptions);

      this.lastObjectObserver = new IntersectionObserver((entry, obs) => {
        const oldRightShadow = this.showRightShadow;
        this.showRightShadow = entry[0].intersectionRatio < 0.9;
        if (oldRightShadow !== this.showRightShadow) {
          this.changeDetector.markForCheck();
        }
      }, observableOptions);

      [this.firstObservedElem] = megaMenuItem;
      this.lastObserveElem = megaMenuItem[megaMenuItem.length - 1];

      this.firstObjectObserver.observe(megaMenuItem[0].nativeElement);
      this.lastObjectObserver.observe(
        megaMenuItem[megaMenuItem.length - 1].nativeElement,
      );
    }
  }

  handleisActiveTrigger(status: boolean, index: number): void {
    if (status) {
      this.activeIndex = index;
    } else if (this.activeIndex === index) {
      this.activeIndex = -1;
    }
  }

  trackByName(_: number, item: SeoNavbarParent | SeoNavbarLink): string {
    return `${item.name} : ${item.relativeUrl}`;
  }
}
