import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContainerSize } from '@freelancer/ui/container';

@Component({
  selector: 'webapp-compat-seo-navbar',
  template: `
    <app-seo-navbar
      [containerSize]="containerSize"
      [isCrawler]="isCrawler"
    ></app-seo-navbar>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompatSeoNavbarComponent implements OnInit {
  containerSize = ContainerSize.DESKTOP_LARGE;

  isCrawler: boolean;

  ngOnInit(): void {
    if (window.webappCompat && window.webappCompat['seo-navbar']) {
      this.containerSize =
        (window.webappCompat['seo-navbar'].containerSize as ContainerSize) ??
        ContainerSize.DESKTOP_LARGE;

      this.isCrawler = !!window.webappCompat['seo-navbar'].isCrawler;
    }
  }
}
